import React, { lazy } from 'react'
import { TimeOffForm } from './TimeAttendance/TimeOffApplication/TimeOffForm'
import { TimeOffListing } from './TimeAttendance/TimeOffApplication/TimeOffListing'
import { TodoOvertimeDetails } from './Todo/TodoOvertimeDetail'
import { TodoAttendanceCorrectionRequest } from './Todo/TodoAttendanceCorrectionRequest'
import { TodoAttendanceCorrectionRequestDetails } from './Todo/TodoAttendanceCorrectionRequestDetails'

const MyMenu = lazy(() =>
  import('./MyMenu').then(module => ({
    default: module.MyMenu,
  }))
)

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)

const PayrollManagementListing = lazy(() =>
  import('./PayrollManagement/PayrollManagementListing').then(module => ({
    default: module.PayrollManagementListing,
  }))
)

const DigitalReportingManagementListing = lazy(() =>
  import('./DigitalReportingManagement/DigitalReportingManagementListing').then(
    module => ({
      default: module.DigitalReportingManagementListing,
    })
  )
)

const PayrollManagementEmployeeListing = lazy(() =>
  import('./PayrollManagement/PayrollManagementEmployeeListing').then(
    module => ({
      default: module.PayrollManagementEmployeeListing,
    })
  )
)
const MonthlyRemunerationEmployeeListing = lazy(() =>
  import(
    './PayrollManagement/MonthlyRemuneration/MonthlyRemunerationListing'
  ).then(module => ({
    default: module.MonthlyRemunerationEmployeeListing,
  }))
)
const EmployeeSalaryMenu = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeSalaryMenu').then(
    module => ({
      default: module.EmployeeSalaryMenu,
    })
  )
)
const EmployeeSalaryLogsListing = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeSalaryLogsListing').then(
    module => ({
      default: module.EmployeeSalaryLogsListing,
    })
  )
)
const EmployeeEnquiry = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeEnquiry').then(module => ({
    default: module.EmployeeEnquiry,
  }))
)
const EmployeeExcell = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeExcell').then(module => ({
    default: module.EmployeeExcell,
  }))
)
const EmployeeValidationExcell = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeValidationExcell').then(
    module => ({
      default: module.EmployeeValidationExcell,
    })
  )
)

const PayPeriodListing = lazy(() =>
  import('./PayrollManagement/PayPeriodListing').then(module => ({
    default: module.PayPeriodListing,
  }))
)

// const PersonnelListing = lazy(() =>
//   import('./PersonnelManagement/PersonnelEFile/PersonnelListing').then(
//     module => ({
//       default: module.PersonnelListing,
//     })
//   )
// )

// const StaffConfirmationListing = lazy(() =>
//   import(
//     './PersonnelManagement/StaffConfirmation/StaffConfirmationListing'
//   ).then(module => ({
//     default: module.StaffConfirmationListing,
//   }))
// )

const ModuleCompanyListing = lazy(() =>
  import('./ModuleCompanyListing').then(module => ({
    default: module.ModuleCompanyListing,
  }))
)

const NewLeaveRecordDetails = lazy(() =>
  import('./Subordinates/LeaveRecord/NewLeaveRecordDetails').then(module => ({
    default: module.NewLeaveRecordDetails,
  }))
)
const NewLeaveRecordDetailsForm = lazy(() =>
  import('./Subordinates/LeaveRecord/NewLeaveRecordDetailsForm').then(
    module => ({
      default: module.NewLeaveRecordDetailsForm,
    })
  )
)
const EntitlementLeaveTable = lazy(() =>
  import('./Subordinates/LeaveRecord/EntitlementLeaveTable').then(module => ({
    default: module.EntitlementLeaveTable,
  }))
)
const NewLeaveSubDetail = lazy(() =>
  import('./Subordinates/LeaveRecord/NewLeaveSubDetail').then(module => ({
    default: module.NewLeaveSubDetail,
  }))
)
const ReplacementLeaveSubTable = lazy(() =>
  import('./Subordinates/LeaveRecord/ReplacementLeaveSubTable').then(
    module => ({
      default: module.ReplacementLeaveSubTable,
    })
  )
)

const PayrollCompanySetting = lazy(() =>
  import('./PayrollManagement/CompanySetting/PayrollCompanySetting').then(
    module => ({
      default: module.PayrollCompanySetting,
    })
  )
)
const PayrollInitiationPayCycle = lazy(() =>
  import('./PayrollManagement/PayrollInitiation/InitiationPayCycle').then(
    module => ({
      default: module.InitiationPayCycle,
    })
  )
)

const PayrollTemplateLibraryAssignment = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/TemplateLibraryAssignment/PayrollTemplateLibraryAssignment'
  ).then(module => ({
    default: module.PayrollTemplateLibraryAssignment,
  }))
)

const EnhancedPayrollTemplateLibraryAssignment = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/TemplateLibraryAssignment/EnhancedPayrollTemplateLibraryAssignment'
  ).then(module => ({
    default: module.EnhancedPayrollTemplateLibraryAssignment,
  }))
)

const PayrollSetting = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/PayrollSetting/PayrollSetting'
  ).then(module => ({
    default: module.PayrollSetting,
  }))
)

// const PayrollGLMappingAssignment = lazy(() =>
//   import(
//     './SystemAdmin/GeneralSetting/CompanySetting/PayrollGLMappingAssignment/GLMappingAssignmentForm'
//   ).then(module => ({
//     default: module.GLMappingAssignmentForm,
//   }))
// )

const BankListing = lazy(() =>
  import('./PayrollManagement/CompanySetting/Bank/BankListing').then(
    module => ({
      default: module.BankListing,
    })
  )
)

const PayrollGLAccountMappingAssignment = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/GLMappingAssignment/GLMappingAssignmentForm'
  ).then(module => ({
    default: module.GLMappingAssignmentForm,
  }))
)

const BankForm = lazy(() =>
  import('./PayrollManagement/CompanySetting/Bank/BankForm').then(module => ({
    default: module.BankForm,
  }))
)

const InterBankListing = lazy(() =>
  import('./PayrollManagement/CompanySetting/Bank/InterBankListing').then(
    module => ({
      default: module.InterBankListing,
    })
  )
)

const UserDefineEPFPolicyMatrixListing = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixListing'
  ).then(module => ({
    default: module.UserDefineEPFPolicyMatrixListing,
  }))
)

const UserDefineEPFPolicyMatrixForm = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixForm'
  ).then(module => ({
    default: module.UserDefineEPFPolicyMatrixForm,
  }))
)

const UserDefineEmployerEPFVEPolicyMatrixListing = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixListing'
  ).then(module => ({
    default: module.UserDefineEmployerEPFVEPolicyMatrixListing,
  }))
)

const UserDefineEmployerEPFVEPolicyMatrixForm = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixForm'
  ).then(module => ({
    default: module.UserDefineEmployerEPFVEPolicyMatrixForm,
  }))
)

const HomeLeave = lazy(() =>
  import('./EmployeeMyLeave/MyLeave').then(module => ({
    default: module.MyLeave,
  }))
)

const MyLeaveDetail = lazy(() =>
  import('./EmployeeMyLeave/MyLeaveDetail').then(module => ({
    default: module.MyLeaveDetail,
  }))
)

const NewMyLeave = lazy(() =>
  import('./EmployeeMyLeave/NewMyLeave').then(module => ({
    default: module.NewMyLeave,
  }))
)

const HomeLeaveForm = lazy(() =>
  import('./EmployeeMyLeave/MyLeaveForm').then(module => ({
    default: module.MyLeaveForm,
  }))
)

const NewMyLeaveForm = lazy(() =>
  import('./EmployeeMyLeave/NewMyLeaveForm').then(module => ({
    default: module.NewMyLeaveForm,
  }))
)

const EntitlementTable = lazy(() =>
  import('./EmployeeMyLeave/EntitlementTable').then(module => ({
    default: module.EntitlementTable,
  }))
)

const ReplacementLeaveTable = lazy(() =>
  import('./EmployeeMyLeave/ReplacementLeaveTable').then(module => ({
    default: module.ReplacementLeaveTable,
  }))
)

const ApprovalHistory = lazy(() =>
  import('./Todo/ApprovalHistory').then(module => ({
    default: module.ApprovalHistory,
  }))
)

const HomeMileage = lazy(() =>
  import('./EmployeeMyClaim/ClaimMileageListing').then(module => ({
    default: module.ClaimMileageListing,
  }))
)

const ViewMileageLogGroup = lazy(() =>
  import('./EmployeeMyClaim/ViewMileageLogGroup').then(module => ({
    default: module.ViewMileageLogGroup,
  }))
)

const MileageItemForm = lazy(() =>
  import('./EmployeeMyClaim/MileageItemForm').then(module => ({
    default: module.MileageItemForm,
  }))
)

const MyMileageLog = lazy(() =>
  import('./EmployeeMyClaim/MyMileageLog').then(module => ({
    default: module.MyMileageLog,
  }))
)

const HomeClaim = lazy(() =>
  import('./EmployeeMyClaim/MyClaimListing').then(module => ({
    default: module.MyClaimListing,
  }))
)

const MyClaimForm = lazy(() =>
  import('./EmployeeMyClaim/MyClaimForm').then(module => ({
    default: module.MyClaimForm,
  }))
)

const MyClaimDetail = lazy(() =>
  import('./EmployeeMyClaim/MyClaimDetail').then(module => ({
    default: module.MyClaimDetail,
  }))
)

const MyClaimItemDetail = lazy(() =>
  import('./EmployeeMyClaim/MyClaimItemDetail').then(module => ({
    default: module.MyClaimItemDetail,
  }))
)

const MyClaimEntitlementTable = lazy(() =>
  import('./EmployeeMyClaim/MyClaimEntitlementTable').then(module => ({
    default: module.EntitlementTable,
  }))
)

const ClaimItemForm = lazy(() =>
  import('./EmployeeMyClaim/ClaimItemForm').then(module => ({
    default: module.ClaimItemForm,
  }))
)

const HomeRequisition = lazy(() =>
  import('./EmployeeMyRequisition/MyRequisitionListing').then(module => ({
    default: module.MyRequisitionListing,
  }))
)

const HomeRequisitionForm = lazy(() =>
  import('./EmployeeMyRequisition/MyRequisitionForm').then(module => ({
    default: module.MyRequisitionForm,
  }))
)

const MyRequisitionDetail = lazy(() =>
  import('./EmployeeMyRequisition/MyRequisitionDetail').then(module => ({
    default: module.MyRequisitionDetail,
  }))
)

const HomeDigitalDocument = lazy(() =>
  import('./DigitalDocument/DigitalDocumentView').then(module => ({
    default: module.DigitalDocumentView,
  }))
)

const HomeDigitalDocuments = lazy(() =>
  import('./DigitalDocument/EmployeeDocumentList').then(module => ({
    default: module.EmployeeDocumentList,
  }))
)

const HomeNotification = lazy(() =>
  import('./Notification/HomeNotification').then(module => ({
    default: module.HomeNotification,
  }))
)

const HomeNotificationDetail = lazy(() =>
  import('./Notification/HomeNotificationDetail').then(module => ({
    default: module.HomeNotificationDetail,
  }))
)
const HomeNotificationDataChangesDetail = lazy(() =>
  import('./Notification/HomeNotificationDataChangesDetail').then(module => ({
    default: module.HomeNotificationDataChangesDetail,
  }))
)

const HomeNotificationRecruitmentRequisitionDetail = lazy(() =>
  import('./Notification/HomeNotificationRecruitmentRequisitionDetail').then(
    module => ({
      default: module.HomeNotificationRecruitmentRequisitionDetail,
    })
  )
)

const HomeNotificationRecruitmentOfferDetail = lazy(() =>
  import('./Notification/HomeNotificationRecruitmentOfferDetail').then(
    module => ({
      default: module.HomeNotificationRecruitmentOfferDetail,
    })
  )
)

const HomeNotificationRecruitmentHiringDetail = lazy(() =>
  import('./Notification/HomeNotificationRecruitmentHiringDetail').then(
    module => ({
      default: module.HomeNotificationRecruitmentHiringDetail,
    })
  )
)

// const HomeTodo = lazy(() =>
//   import('./Todo/Todo').then(module => ({
//     default: module.Todo,
//   }))
// )
const TodoDetail = lazy(() =>
  import('./Todo/TodoDetail').then(module => ({
    default: module.TodoDetail,
  }))
)

const TodoClaimDetail = lazy(() =>
  import('./Todo/TodoClaimDetail').then(module => ({
    default: module.TodoClaimDetail,
  }))
)

const TodoRequisitionDetail = lazy(() =>
  import('./Todo/TodoRequisitionDetail').then(module => ({
    default: module.TodoRequisitionDetail,
  }))
)

const TodoRequisitionDetailV2 = lazy(() =>
  import('./Todo/ToDoRequisitionDetailV2').then(module => ({
    default: module.TodoRequisitionDetailV2,
  }))
)

const TodoProjectExpenseDetail = lazy(() =>
  import('./Todo/TodoProjectExpenseDetail').then(module => ({
    default: module.TodoProjectExpenseDetail,
  }))
)

const MySubordinatesListing = lazy(() =>
  import('./Subordinates/MySubordinatesListing').then(module => ({
    default: module.MySubordinatesListing,
  }))
)

const MySubordinatesSummary = lazy(() =>
  import('./Subordinates/MySubordinatesSummary').then(module => ({
    default: module.MySubordinatesSummary,
  }))
)

const MySubordinatesSummaryOlderVersion = lazy(() =>
  import('./Subordinates/MySubordinatesSummaryOlderVersion').then(module => ({
    default: module.MySubordinatesSummaryOlderVersion,
  }))
)

const EmployeeInfoDetails = lazy(() =>
  import('./Subordinates/EmployeeInfo/EmployeeInfoDetails').then(module => ({
    default: module.EmployeeInfoDetails,
  }))
)
const AssetAssignedDetails = lazy(() =>
  import('./Subordinates/AssetAssigned/AssetAssignedDetails').then(module => ({
    default: module.AssetAssignedDetails,
  }))
)
const LeaveRecordsDetails = lazy(() =>
  import('./Subordinates/LeaveRecord/LeaveRecordsDetails').then(module => ({
    default: module.LeaveRecordsDetails,
  }))
)

const ClaimRecordsDetails = lazy(() =>
  import('./Subordinates/ClaimRecords/ClaimRecordsDetails').then(module => ({
    default: module.ClaimRecordsDetails,
  }))
)

const CareerProgressionDetails = lazy(() =>
  import('./Subordinates/CareerProgression/CareerProgressionDetails').then(
    module => ({
      default: module.CareerProgressionDetails,
    })
  )
)
const RequisitionRecordsDetails = lazy(() =>
  import('./Subordinates/RequisitionRecords/RequisitionRecordsDetails').then(
    module => ({
      default: module.RequisitionRecordsDetails,
    })
  )
)

const EmployeeSubordinates = lazy(() =>
  import('./Subordinates/Subordinates/EmployeeSubordinates').then(module => ({
    default: module.EmployeeSubordinates,
  }))
)

const EmployeeInfo = lazy(() =>
  import('./Subordinates/EmployeeInfo/EmployeeInfo').then(module => ({
    default: module.EmployeeInfo,
  }))
)

const EmployeeJobInfoDetails = lazy(() =>
  import('./Subordinates/EmployeeInfo/EmployeeJobInfoDetails').then(module => ({
    default: module.EmployeeJobInfoDetails,
  }))
)

const SubordinatePersonalStatutoryDetails = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalStatInfo/PersonalStatDetails'
  ).then(module => ({
    default: module.SubordinatePersonalStatDetails,
  }))
)

const SubordinatePersonalTP3Details = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalTP3/PersonalTP3Details'
  ).then(module => ({
    default: module.SubordinatePersonalTP3Details,
  }))
)

const CareerProgression = lazy(() =>
  import('./CareerProgression/CareerProgression').then(module => ({
    default: module.CareerProgression,
  }))
)

const HomeBulletin = lazy(() =>
  import('./Bulletin/HomeBulletin').then(module => ({
    default: module.HomeBulletin,
  }))
)

const HomeCalendar = lazy(() =>
  import('./Calendar/HomeCalendar').then(module => ({
    default: module.HomeCalendar,
  }))
)

const GeneralDocument = lazy(() =>
  import('./DigitalDocument/GeneralDocument/GeneralDocumentListing').then(
    module => ({
      default: module.GeneralDocumentListing,
    })
  )
)

const PersonalDocument = lazy(() =>
  import('./DigitalDocument/PersonalDocument/PersonalDocumentListing').then(
    module => ({
      default: module.PersonalDocumentListing,
    })
  )
)

const PayslipListing = lazy(() =>
  import('./DigitalDocument/Payslip/PayslipListing').then(module => ({
    default: module.PayslipListing,
  }))
)

const EAFormListing = lazy(() =>
  import('./DigitalDocument/EAForm/EAFormListing').then(module => ({
    default: module.EAFormListing,
  }))
)

const ELetterListing = lazy(() =>
  import('./DigitalDocument/ELetter/ELetterListing').then(module => ({
    default: module.ELetterListing,
  }))
)

const ELetterView = lazy(() =>
  import('./DigitalDocument/ELetter/ELetterView').then(module => ({
    default: module.ELetterView,
  }))
)

const EAFormView = lazy(() =>
  import('./DigitalDocument/EAForm/EAFormView').then(module => ({
    default: module.EAFormView,
  }))
)

const PayslipView = lazy(() =>
  import('./DigitalDocument/Payslip/PaySlipView').then(module => ({
    default: module.PayslipView,
  }))
)
//// Time Attendance ////
const TimeAttendanceView = lazy(() =>
  import('./TimeAttendance/TimeAttendanceView').then(module => ({
    default: module.TimeAttendanceView,
  }))
)

const OvertimeListing = lazy(() =>
  import('./TimeAttendance/OvertimeApplication/OvertimeListing').then(
    module => ({
      default: module.OvertimeListing,
    })
  )
)

const OvertimeDetails = lazy(() =>
  import('./TimeAttendance/OvertimeApplication/OvertimeDetails').then(
    module => ({
      default: module.OvertimeDetails,
    })
  )
)

const TimeOffDetails = lazy(() =>
  import('./TimeAttendance/TimeOffApplication/TimeOffDetails').then(module => ({
    default: module.TimeOffDetails,
  }))
)

const ChangeShiftRequestDetails = lazy(() =>
  import('./TimeAttendance/ChangeShiftRequest/ChangeShiftRequestDetails').then(
    module => ({
      default: module.ChangeShiftRequestDetails,
    })
  )
)

const OvertimeForm = lazy(() =>
  import('./TimeAttendance/OvertimeApplication/OvertimeForm').then(module => ({
    default: module.OvertimeForm,
  }))
)

const EmpAttendance = lazy(() =>
  import('./TimeAttendance/AttendanceAnalysis/AttendanceAnalysis').then(
    module => ({
      default: module.AttendanceAnalysis,
    })
  )
)

const EmpCheckIn = lazy(() =>
  import('./TimeAttendance/CheckIn/CheckIn').then(module => ({
    default: module.CheckIn,
  }))
)

const EmpCheckOut = lazy(() =>
  import('./TimeAttendance/CheckIn/CheckOut').then(module => ({
    default: module.CheckOut,
  }))
)

const MySubordinatesBatchOvertimeEntryListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/BatchOvertimeEntry/MySubordinatesBatchOvertimeEntryListing'
  ).then(module => ({
    default: module.MySubordinatesBatchOvertimeEntryListing,
  }))
)
const MySubordinatesBatchOvertimeEntryForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/BatchOvertimeEntry/MySubordinatesBatchOvertimeEntryForm'
  ).then(module => ({
    default: module.MySubordinatesBatchOvertimeEntryForm,
  }))
)
const MySubordinatesBatchOvertimeEntryView = lazy(() =>
  import(
    './Subordinates/TimeAttendance/BatchOvertimeEntry/MySubordinatesBatchOvertimeEntryView'
  ).then(module => ({
    default: module.MySubordinatesBatchOvertimeEntryView,
  }))
)

// const CheckInHistory = lazy(() =>
//   import('./TimeAttendance/CheckIn/History').then(module => ({
//     default: module.CheckInHistory,
//   }))
// )
const ChangeShiftRequestListing = lazy(() =>
  import('./TimeAttendance/ChangeShiftRequest/ChangeShiftRequestListing').then(
    module => ({
      default: module.ChangeShiftRequestListing,
    })
  )
)

const ChangeShiftRequestForm = lazy(() =>
  import('./TimeAttendance/ChangeShiftRequest/ChangeShiftRequestForm').then(
    module => ({
      default: module.ChangeShiftRequestForm,
    })
  )
)

// const CheckInHistory = lazy(() =>
//   import('./TimeAttendance/CheckIn/History').then(module => ({
//     default: module.CheckInHistory,
//   }))
// )

const MyMenuAttendanceCorrectionRequestListing = lazy(() =>
  import(
    './TimeAttendance/AttendanceCorrectionRequest/AttendanceCorrectionRequestListing'
  ).then(module => ({
    default: module.AttendanceCorrectionRequestListing,
  }))
)

const MyMenuAttendanceCorrectionRequestForm = lazy(() =>
  import(
    './TimeAttendance/AttendanceCorrectionRequest/AttendanceCorrectionRequestForm'
  ).then(module => ({
    default: module.AttendanceCorrectionRequestForm,
  }))
)

const MyMenuAttendanceCorrectionRequestView = lazy(() =>
  import(
    './TimeAttendance/AttendanceCorrectionRequest/AttendanceCorrectionRequestDetail'
  ).then(module => ({
    default: module.AttendanceCorrectionRequestDetail,
  }))
)

const SubordinateAttendanceCorrectionRequestListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateAttendanceCorrectionRequest/SubordinateAttendanceCorrectionRequestListing'
  ).then(module => ({
    default: module.SubordinateAttendanceCorrectionRequestListing,
  }))
)

const SubordinateAttendanceCorrectionRequestForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateAttendanceCorrectionRequest/SubordinateAttendanceCorrectionRequestForm'
  ).then(module => ({
    default: module.SubordinateAttendanceCorrectionRequestForm,
  }))
)

const SubordinateAttendanceCorrectionRequestDetail = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateAttendanceCorrectionRequest/SubordinateAttendanceCorrectionRequestDetail'
  ).then(module => ({
    default: module.SubordinateAttendanceCorrectionRequestDetail,
  }))
)

//// Delegation ////

const DelegationListing = lazy(() =>
  import('./Delegation/DelegationListing').then(module => ({
    default: module.DelegationListing,
  }))
)

const DelegationForm = lazy(() =>
  import('./Delegation/DelegationForm').then(module => ({
    default: module.DelegationForm,
  }))
)

const DelegationView = lazy(() =>
  import('./Delegation/DelegationView').then(module => ({
    default: module.DelegationView,
  }))
)

const CheckInHistory = lazy(() =>
  import('./TimeAttendance/CheckIn/History').then(module => ({
    default: module.CheckInHistory,
  }))
)

const PersonalNav = lazy(() =>
  import('./PersonalProfile/PersonalNav').then(module => ({
    default: module.PersonalNav,
  }))
)

const EmploymentInfoNav = lazy(() =>
  import('./EmploymentInfo/EmploymentInfoNav').then(module => ({
    default: module.EmploymentInfoNav,
  }))
)

const DiaryListing = lazy(() =>
  import('./Diary/DiaryListing').then(module => ({
    default: module.DiaryListing,
  }))
)

const DiaryForm = lazy(() =>
  import('./Diary/DiaryForm').then(module => ({
    default: module.DiaryForm,
  }))
)

const PersonalInfoDetails = lazy(() =>
  import('./PersonalProfile/PersonalInfo/PersonalInfoDetails').then(module => ({
    default: module.PersonalInfoDetails,
  }))
)

const PersonalInfoDetailsForm = lazy(() =>
  import('./PersonalProfile/PersonalInfo/PersonalInfoDetailsForm').then(
    module => ({
      default: module.PersonalInfoDetailsForm,
    })
  )
)

const PersonalJobDetails = lazy(() =>
  import('./PersonalProfile/PersonalJobInfo/PersonalJobDetails').then(
    module => ({
      default: module.PersonalJobDetails,
    })
  )
)

const PersonalStatDetails = lazy(() =>
  import('./PersonalProfile/PersonalStatInfo/PersonalStatDetails').then(
    module => ({
      default: module.PersonalStatDetails,
    })
  )
)

const PersonalDependentDetails = lazy(() =>
  import('./PersonalProfile/PersonalDependent/PersonalDependentDetails').then(
    module => ({
      default: module.PersonalDependentDetails,
    })
  )
)
const PersonalDependentView = lazy(() =>
  import('./PersonalProfile/PersonalDependent/PersonalDependentView').then(
    module => ({
      default: module.PersonalDependentView,
    })
  )
)

const DependentForm = lazy(() =>
  import('./PersonalProfile/PersonalDependent/PersonalDependentForm').then(
    module => ({
      default: module.DependentForm,
    })
  )
)

const EducationList = lazy(() =>
  import('./Education/EducationList').then(module => ({
    default: module.EducationList,
  }))
)

const EducationForm = lazy(() =>
  import('./Education/EducationForm').then(module => ({
    default: module.EducationForm,
  }))
)

const CertificateListing = lazy(() =>
  import('./Certificate/CertificateListing').then(module => ({
    default: module.CertificateListing,
  }))
)

const CertificateForm = lazy(() =>
  import('./Certificate/CertificateForm').then(module => ({
    default: module.CertificateForm,
  }))
)

const ReferencesListing = lazy(() =>
  import('./References/ReferencesListing').then(module => ({
    default: module.ReferencesListing,
  }))
)

const ReferencesForm = lazy(() =>
  import('./References/ReferencesForm').then(module => ({
    default: module.ReferencesForm,
  }))
)

const HealthInfoForm = lazy(() =>
  import('./HealthInfo/HealthInfoForm').then(module => ({
    default: module.HealthInfoForm,
  }))
)

const JobHistoryListing = lazy(() =>
  import('./JobHistory/JobHistoryListing').then(module => ({
    default: module.JobHistoryListing,
  }))
)

const JobHistoryForm = lazy(() =>
  import('./JobHistory/JobHistoryForm').then(module => ({
    default: module.JobHistoryForm,
  }))
)

const UserDefineForm = lazy(() =>
  import('./UserDefine/UserDefineForm').then(module => ({
    default: module.UserDefineForm,
  }))
)

const PersonalDocumentListing = lazy(() =>
  import('./PersonalDocument/PersonalDocumentList').then(module => ({
    default: module.PersonalDocumentListing,
  }))
)

const PersonalTP3Details = lazy(() =>
  import('./PersonalProfile/PersonalTP3/PersonalTP3Details').then(module => ({
    default: module.PersonalTP3Details,
  }))
)

const AssetAssignedHome = lazy(() =>
  import('./HomeAssetAssigned/HomeAsset').then(module => ({
    default: module.AssetAssignedHome,
  }))
)

const InsuranceSchemeHome = lazy(() =>
  import('./HomeInsuranceScheme/InsuranceScheme').then(module => ({
    default: module.InsuranceScheme,
  }))
)

const MyDutyRoster = lazy(() =>
  import('./TimeAttendance/MyDutyRoster/MyDutyRoster').then(module => ({
    default: module.MyDutyRoster,
  }))
)

const TodoCalendar = lazy(() =>
  import('./Todo/TodoCalendar').then(module => ({
    default: module.TodoCalendar,
  }))
)

const TodoLeaveView = lazy(() =>
  import('./Todo/TodoLeaveView').then(module => ({
    default: module.TodoLeaveView,
  }))
)

const TodoMenu = lazy(() =>
  import('./Todo/TodoMenu').then(module => ({
    default: module.TodoMenu,
  }))
)

const TodoSubKpi = lazy(() =>
  import('./Todo/TodoKPIPlanningApproval/SubordinateAppraisalListing').then(
    module => ({
      default: module.SubordinateAppraisalListing,
    })
  )
)

const TodoSubKpiApproval = lazy(() =>
  import('./Todo/TodoKPIPlanningApproval/SubordinateAppraisalApproval').then(
    module => ({
      default: module.SubordinateAppraisalApproval,
    })
  )
)

const TodoLeave = lazy(() =>
  import('./Todo/TodoLeave').then(module => ({
    default: module.TodoLeave,
  }))
)
const TodoClaim = lazy(() =>
  import('./Todo/TodoClaim').then(module => ({
    default: module.TodoClaim,
  }))
)
const TodoRequisition = lazy(() =>
  import('./Todo/TodoRequisition').then(module => ({
    default: module.TodoRequisition,
  }))
)
const TodoOverTime = lazy(() =>
  import('./Todo/TodoOverTime').then(module => ({
    default: module.TodoOverTime,
  }))
)
const TodoOverTimeDetails = lazy(() =>
  import('./Todo/TodoOvertimeDetails').then(module => ({
    default: module.TodoOvertimeDetails,
  }))
)
const TodoBatchOverTime = lazy(() =>
  import('./Todo/TodoBatchOvertimeEntry').then(module => ({
    default: module.TodoBatchOvertimeEntry,
  }))
)
const TodoBatchOverTimeEntryApprovalForm = lazy(() =>
  import('./Todo/TodoBatchOvertimeEntryApprovalForm').then(module => ({
    default: module.TodoBatchOvertimeEntryApprovalForm,
  }))
)
const TodoTimeOff = lazy(() =>
  import('./Todo/TodoTimeOff').then(module => ({
    default: module.TodoTimeOff,
  }))
)
const TodoChangeShift = lazy(() =>
  import('./Todo/TodoChangeShift').then(module => ({
    default: module.TodoChangeShift,
  }))
)
const TodoProjectExpense = lazy(() =>
  import('./Todo/TodoProjectExpense').then(module => ({
    default: module.TodoProjectExpense,
  }))
)
const ToDoDataChanges = lazy(() =>
  import('./Todo/TodoDataChanges').then(module => ({
    default: module.ToDoDataChanges,
  }))
)
const ToDoDataChangesDetails = lazy(() =>
  import('./Todo/TodoDataChangesDetails').then(module => ({
    default: module.ToDoDataChangesDetails,
  }))
)

const TodoRecruitmentRequisition = lazy(() =>
  import('./Todo/TodoRecruitmentRequisition').then(module => ({
    default: module.TodoRecruitmentRequisition,
  }))
)

const TodoRecruitmentRequisitionDetail = lazy(() =>
  import('./Todo/TodoRecruitmentRequisitionDetail').then(module => ({
    default: module.TodoRecruitmentRequisitionDetail,
  }))
)

const TodoRecruitmentOffer = lazy(() =>
  import('./Todo/TodoRecruitmentOffer').then(module => ({
    default: module.TodoRecruitmentOffer,
  }))
)

const TodoRecruitmentOfferDetail = lazy(() =>
  import('./Todo/TodoRecruitmentOfferDetail').then(module => ({
    default: module.TodoRecruitmentOfferDetail,
  }))
)

const TodoRecruitmentHiring = lazy(() =>
  import('./Todo/TodoRecruitmentHiring').then(module => ({
    default: module.TodoRecruitmentHiring,
  }))
)

const TodoRecruitmentHiringDetail = lazy(() =>
  import('./Todo/TodoRecruitmentHiringDetail').then(module => ({
    default: module.TodoRecruitmentHiringDetail,
  }))
)

const MyTimeSheetMenu = lazy(() =>
  import('../HomeModule/MyTimeSheet/MyTimeSheetMenu').then(module => ({
    default: module.MyTimeSheetMenu,
  }))
)
const ProjectTimeSheetListing = lazy(() =>
  import('../HomeModule/MyTimeSheet/ProjectTimeSheetListing').then(module => ({
    default: module.ProjectTimeSheetListing,
  }))
)
const ProjectTimeSheetEntrySubmission = lazy(() =>
  import('../HomeModule/MyTimeSheet/ProjectTimeSheetEntrySubmission').then(
    module => ({
      default: module.ProjectTimeSheetEntrySubmission,
    })
  )
)
const TimeSheetListing = lazy(() =>
  import('../HomeModule/MyTimeSheet/TimeSheetListing').then(module => ({
    default: module.TimeSheetListing,
  }))
)
const TimeSheetForm = lazy(() =>
  import('../HomeModule/MyTimeSheet/TimeSheetForm').then(module => ({
    default: module.TimeSheetForm,
  }))
)
const StaffVersion = lazy(() =>
  import('../HomeModule/MyKPI/StaffVersion').then(module => ({
    default: module.StaffVersion,
  }))
)
const StaffKpiForm = lazy(() =>
  import('../HomeModule/MyKPI/StaffKpiForm').then(module => ({
    default: module.StaffKpiForm,
  }))
)
const StaffKpiDetailForm = lazy(() =>
  import('../HomeModule/MyKPI/StaffKpiDetailForm').then(module => ({
    default: module.StaffKpiDetailForm,
  }))
)
const ScaleForm = lazy(() =>
  import('../HomeModule/MyKPI/ScaleForm').then(module => ({
    default: module.ScaleForm,
  }))
)
const SubordinateListing = lazy(() =>
  import('../HomeModule/MySubordinate/SubordinateListing').then(module => ({
    default: module.SubordinateListing,
  }))
)
const SubordinateHome = lazy(() =>
  import('./MySubordinate/SubordinateHome').then(module => ({
    default: module.SubordinateHome,
  }))
)
const TimeSheetSubordinateListing = lazy(() =>
  import(
    './MySubordinate/SubordinateModule/TimeSheet/TimeSheetSubordinateListing'
  ).then(module => ({
    default: module.TimeSheetSubordinateListing,
  }))
)

const ProjectExpenseListing = lazy(() =>
  import('./ProjectExpenseModule/ProjectExpenseListing').then(module => ({
    default: module.ProjectExpenseListing,
  }))
)

const SubordinateTreePersonalInfo = lazy(() =>
  import('./Subordinates/SubordinateTreePersonalInfo').then(module => ({
    default: module.SubordinateTreePersonalInfo,
  }))
)

const SubordinateTreeTimeAttendance = lazy(() =>
  import('./Subordinates/TimeAttendanceSubordinateTree').then(module => ({
    default: module.SubordinateTreeTimeAttendance,
  }))
)

const SubordinateTimeAttendanceMenu = lazy(() =>
  import('./Subordinates/TimeAttendance/SubordinateTimeAttendanceMenu').then(
    module => ({
      default: module.SubordinateTimeAttendanceMenu,
    })
  )
)

const SubordinateTimeAttendanceMainMenu = lazy(() =>
  import('./Subordinates/TimeAttendance/SubordinateTimeAttedanceMainMenu').then(
    module => ({
      default: module.SubordinateTimeAttendanceMainMenu,
    })
  )
)

const SubordinateDutyRoster = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateDutyRoster/SubordinateDutyRoster'
  ).then(module => ({
    default: module.SubordinateDutyRoster,
  }))
)

const SubordinateOvertimeListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateOvertime/SubordinateOvertimeListing'
  ).then(module => ({
    default: module.SubordinateOvertimeListing,
  }))
)

const SubordinateOvertimeView = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateOvertime/SubordinateOvertimeView'
  ).then(module => ({
    default: module.SubordinateOvertimeView,
  }))
)

const SubordinateOvertimeForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateOvertime/SubordinateOvertimeForm'
  ).then(module => ({
    default: module.SubordinateOvertimeForm,
  }))
)

const SubordinateTimeOffListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateTimeOff/SubordinateTimeOffListing'
  ).then(module => ({
    default: module.SubordinateTimeOffListing,
  }))
)

const SubordinateTimeOffForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateTimeOff/SubordinateTimeOffForm'
  ).then(module => ({
    default: module.SubordinateTimeOffForm,
  }))
)

const SubordinateTimeOffDetails = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateTimeOff/SubordinateTimeOffDetails'
  ).then(module => ({
    default: module.SubordinateTimeOffDetails,
  }))
)

const SubordinateChangeShiftRequestListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateChangeShift/SubordinateChangeShiftListing'
  ).then(module => ({
    default: module.SubordinateChangeShiftRequestListing,
  }))
)

const SubordinateChangeShiftRequestDetails = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateChangeShift/SubordinateChangeShiftDetails'
  ).then(module => ({
    default: module.SubordinateChangeShiftRequestDetails,
  }))
)

const SubordinateChangeShiftRequestForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateChangeShift/SubordinateChangeShiftForm'
  ).then(module => ({
    default: module.SubordinateChangeShiftRequestForm,
  }))
)

const SubordinateAttendanceAnalysis = lazy(() =>
  import(
    './Subordinates/TimeAttendance/AttendanceRecords/SubordinateAttendanceAnalysis'
  ).then(module => ({
    default: module.SubordinateAttendanceAnalysis,
  }))
)

const SubordinateTreeEmployeeInfo = lazy(() =>
  import('./Subordinates/SubordinateTreeEmploymentInfo').then(module => ({
    default: module.SubordinateTreeEmploymentInfo,
  }))
)
const SubordinateAttendanceRecords = lazy(() =>
  import(
    './Subordinates/TimeAttendance/AttendanceRecords/SubordinateAttendanceRecords'
  ).then(module => ({
    default: module.SubordinateAttendanceRecords,
  }))
)

const SubordinateTreeTimesheet = lazy(() =>
  import('./Subordinates/SubordinateTreeTimesheet').then(module => ({
    default: module.SubordinateTreeTimesheet,
  }))
)

const SubordinateTreeLeave = lazy(() =>
  import('./Subordinates/SubordinateTreeLeave').then(module => ({
    default: module.SubordinateTreeLeave,
  }))
)

const SubordinateTreeClaim = lazy(() =>
  import('./Subordinates/SubordinateTreeClaim').then(module => ({
    default: module.SubordinateTreeClaim,
  }))
)

const SubordinateTreeProjectExpense = lazy(() =>
  import('./Subordinates/SubordinateTreeProjectExpense').then(module => ({
    default: module.SubordinateTreeProjectExpense,
  }))
)

const SubordinateEmployeeInfoNav = lazy(() =>
  import('./Subordinates/EmploymentInfo/EmploymentInfoNav').then(module => ({
    default: module.EmploymentInfoNav,
  }))
)

const SubordinatePersonnelInfo = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/SubordinatePersonalNav'
  ).then(module => ({
    default: module.SubordinatePersonalNav,
  }))
)
const SubordinatePersonnelInfoForm = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalInfo/PersonalInfoDetails'
  ).then(module => ({
    default: module.PersonalInfoDetails,
  }))
)

const SubordinatePersonnelEducation = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/Education/EducationList'
  ).then(module => ({
    default: module.EducationList,
  }))
)

const SubordinatePersonnelCert = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/Certificate/CertificateListing'
  ).then(module => ({
    default: module.CertificateListing,
  }))
)

const SubordinatePersonnelRef = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/References/ReferencesListing'
  ).then(module => ({
    default: module.ReferencesListing,
  }))
)
const SubordinatePersonnelHealth = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/HealthInfo/HealthInfoForm'
  ).then(module => ({
    default: module.HealthInfoForm,
  }))
)

const SubordinatePersonnelDependent = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalDependent/PersonalDependentDetails'
  ).then(module => ({
    default: module.PersonalDependentDetails,
  }))
)
const SubordinatePersonnelJobHistory = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/JobHistory/JobHistoryListing'
  ).then(module => ({
    default: module.JobHistoryListing,
  }))
)

const SubordinatePersonnelAdditionalInfo = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/UserDefine/UserDefineForm'
  ).then(module => ({
    default: module.UserDefineForm,
  }))
)

const SubordinatePersonnelDocInfo = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalDocument/PersonalDocumentList'
  ).then(module => ({
    default: module.PersonalDocumentListing,
  }))
)

const SubordinateLeave = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateLeave').then(module => ({
    default: module.SubordinateLeave,
  }))
)

const SubordinateLeaveForm = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateLeaveForm').then(module => ({
    default: module.SubordinateLeaveForm,
  }))
)

const SubordinateLeaveDetail = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateLeaveDetail').then(module => ({
    default: module.SubordinateLeaveDetail,
  }))
)

const SubordinateEntitlementTable = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateEntitlementTable').then(
    module => ({
      default: module.SubordinateEntitlementTable,
    })
  )
)

const SubordinateReplacementLeaveTable = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateReplacementLeaveTable').then(
    module => ({
      default: module.SubordinateReplacementLeaveTable,
    })
  )
)

const SubordinateClaim = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimListing').then(
    module => ({
      default: module.SubordinateClaimListing,
    })
  )
)

const SubordinateClaimForm = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimForm').then(module => ({
    default: module.SubordinateClaimForm,
  }))
)
const SubordinateClaimItemForm = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimItemForm').then(
    module => ({
      default: module.SubordinateClaimItemForm,
    })
  )
)
const SubordinateClaimDetail = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimDetail').then(module => ({
    default: module.SubordinateClaimDetail,
  }))
)

const SubordinateClaimItemDetail = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimItemDetail').then(
    module => ({
      default: module.SubordinateClaimItemDetail,
    })
  )
)

const SubordinateClaimEntitlementTable = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimEntitlementTable').then(
    module => ({
      default: module.SubordinateClaimEntitlementTable,
    })
  )
)

const SubordinateProjectExpense = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseListing'
  ).then(module => ({
    default: module.SubordinateProjectExpenseListing,
  }))
)

const SubordinateProjectExpenseForm = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseForm'
  ).then(module => ({
    default: module.SubordinateProjectExpenseForm,
  }))
)
const SubordinateProjectExpenseClaimItemForm = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseClaimItemForm'
  ).then(module => ({
    default: module.SubordinateProjectExpenseClaimItemForm,
  }))
)
const SubordinateProjectExpenseDetail = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseDetail'
  ).then(module => ({
    default: module.SubordinateProjectExpenseDetail,
  }))
)

const SubordinateRequisitionListing = lazy(() =>
  import(
    './Subordinates/RequisitionRecords/SubordinateRequisitionListing'
  ).then(module => ({
    default: module.SubordinateRequisitionListing,
  }))
)

const SubordinateRequisitionForm = lazy(() =>
  import('./Subordinates/RequisitionRecords/SubordinateRequisitionForm').then(
    module => ({
      default: module.SubordinateRequisitonForm,
    })
  )
)

const SubordinateRequisitionDetail = lazy(() =>
  import('./Subordinates/RequisitionRecords/SubordinateRequisitionDetail').then(
    module => ({
      default: module.SubordinateRequisitionDetail,
    })
  )
)

const MyPayrollHome = lazy(() =>
  import('./MyPayrollInfo/MyPayrollHome').then(module => ({
    default: module.MyPayrollHome,
  }))
)
const PayrollHistoryMonthYear = lazy(() =>
  import('./MyPayrollInfo/ViewPayrollHistory/PayrollHistoryMonthYear').then(
    module => ({
      default: module.PayrollHistoryMonthYear,
    })
  )
)
const PayrollHistoryMonthView = lazy(() =>
  import('./MyPayrollInfo/ViewPayrollHistory/PayrollHistoryMonthView').then(
    module => ({
      default: module.PayrollHistoryMonthView,
    })
  )
)

const HomeProjectExpenseClaim = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimListing').then(
    module => ({
      default: module.MyProjectExpenseClaimListing,
    })
  )
)

const MyProjectExpenseClaimForm = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimForm').then(
    module => ({
      default: module.MyProjectExpenseClaimForm,
    })
  )
)

const MyProjectExpenseClaimDetail = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimDetail').then(
    module => ({
      default: module.MyProjectExpenseClaimDetail,
    })
  )
)

// const MyProjectExpenseClaimEntitlementTable = lazy(() =>
//   import(
//     './EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimEntitlementTable'
//   ).then(module => ({
//     default: module.ProjectExpenseEntitlementTable,
//   }))
// )

const ProjectExpenseClaimItemForm = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/ProjectExpenseClaimItemForm').then(
    module => ({
      default: module.ProjectExpenseClaimItemForm,
    })
  )
)
// const TMSPolicyMatrixMenuList = lazy(() =>
//   import(
//     './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSPolicyMatrixMenuList'
//   ).then(module => ({
//     default: module.TMSPolicyMatrixMenuList,
//   }))
// )

const DataChangeRequestListing = lazy(() =>
  import('../HomeModule/DataChangeRequest/DataChangeRequestListing').then(
    module => ({
      default: module.DataChangeRequestListing,
    })
  )
)

const DataImportListing = lazy(() =>
  import('../HomeModule/DataImport/DataImportListing').then(module => ({
    default: module.DataImportListing,
  }))
)

const DataImport = lazy(() =>
  import('../HomeModule/DataImport/DataImport').then(module => ({
    default: module.DataImport,
  }))
)

const UserActivation = lazy(() =>
  import('../HomeModule/DataImport/UserActivation').then(module => ({
    default: module.UserActivation,
  }))
)

const DataChangeRequestForm = lazy(() =>
  import('../HomeModule/DataChangeRequest/DataChangeRequestForm').then(
    module => ({
      default: module.DataChangeRequestForm,
    })
  )
)

const DataChangePersonnelDetail = lazy(() =>
  import('../HomeModule/DataChangeRequest/DataChangePersonnelDetail').then(
    module => ({
      default: module.DataChangePersonnelDetail,
    })
  )
)

const DataChangeRequestDependentAddForm = lazy(() =>
  import('./DataChangeRequest/DataChangeRequestDependentAddForm').then(
    module => ({
      default: module.DataChangeRequestDependentAddForm,
    })
  )
)

const DataChangeRequestDependentEditForm = lazy(() =>
  import('./DataChangeRequest/DataChangeRequestDependentEditForm').then(
    module => ({
      default: module.DataChangeRequestDependentEditForm,
    })
  )
)

const DataChangeRequestView = lazy(() =>
  import('./DataChangeRequest/DataChangeRequestView').then(module => ({
    default: module.DataChangeRequestView,
  }))
)

const DataChangeRequestEducationAddForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEducation/DataChangeEducationAddForm'
  ).then(module => ({
    default: module.DataChangeRequestEducationAddForm,
  }))
)

const DataChangeRequestEducationEditForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEducation/DataChangeEducationEditForm'
  ).then(module => ({
    default: module.DataChangeEducationEditForm,
  }))
)

const DataChangeRequestCertificateAddForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeCertificate/DataChangeCertificationAddForm'
  ).then(module => ({
    default: module.DataChangeRequestCertificationAddForm,
  }))
)

const DataChangeRequestCertificateEditForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeCertificate/DataChangeCertificateEditForm'
  ).then(module => ({
    default: module.DataChangeCertificateEditForm,
  }))
)

const DataChangeRequestEmploymentHistoryAddForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEmploymentHistory/DataChangeEmploymentHistoryAddForm'
  ).then(module => ({
    default: module.DataChangeEmploymentHistoryAddForm,
  }))
)

const DataChangeRequestEmploymentHistoryEditForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEmploymentHistory/DataChangeEmploymentHistoryEditForm'
  ).then(module => ({
    default: module.DataChangeEmploymentHistoryEditForm,
  }))
)

const HelpdeskListing = lazy(() =>
  import('./HelpdeskModule/HelpdeskListing').then(module => ({
    default: module.HelpdeskListing,
  }))
)

const TodoHelpdesk = lazy(() =>
  import('./Todo/TodoHelpdesk').then(module => ({
    default: module.ToDoHelpdesk,
  }))
)

const ExternalSummaryTimesheet = lazy(() =>
  import('./MyTimeSheet/ExternalSummaryTimesheet').then(module => ({
    default: module.ExternalSummaryTimesheet,
  }))
)

const MyPayrollDocuments = lazy(() =>
  import('./MyPayrollDocuments/MyPayrollDocumentsMenu').then(module => ({
    default: module.MyPayrollDocumentsMenu,
  }))
)
const PayslipDocumentsListing = lazy(() =>
  import('./MyPayrollDocuments/PayslipDocuments/PayslipDocumentsListing').then(
    module => ({
      default: module.PayslipDocumentsListing,
    })
  )
)
const EAFormDocumentsListing = lazy(() =>
  import('./MyPayrollDocuments/EAFormDocuments/EAFormDocumentsListing').then(
    module => ({
      default: module.EAFormDocumentsListing,
    })
  )
)

const EmployeeUserDefineFieldsView = lazy(() =>
  import('./EmployeeUserDefineFields/EmployeeUserDefineFieldsView').then(
    module => ({
      default: module.EmployeeUserDefineFieldsView,
    })
  )
)

const TodoTraining = lazy(() =>
  import('./Todo/TodoTraining').then(module => ({
    default: module.TodoTraining,
  }))
)

const TodoTrainingDetails = lazy(() =>
  import('./Todo/TodoTrainingDetails').then(module => ({
    default: module.TodoTrainingDetails,
  }))
)

const TodoTrainingSurvey = lazy(() =>
  import('./Todo/TodoTrainingSurvey').then(module => ({
    default: module.TodoTrainingSurvey,
  }))
)

const TodoTrainingSurveyDetails = lazy(() =>
  import('./Todo/TodoTrainingSurveyDetails').then(module => ({
    default: module.TodoTrainingSurveyDetails,
  }))
)

const toDoAttendanceCorrection = lazy(() =>
  import('./Todo/TodoAttendanceCorrectionRequest').then(module => ({
    default: module.TodoAttendanceCorrectionRequest,
  }))
)

const TodotoDoAttendanceCorrectionDetails = lazy(() =>
  import('./Todo/TodoAttendanceCorrectionRequestDetails').then(module => ({
    default: module.TodoAttendanceCorrectionRequestDetails,
  }))
)

const OnBehalf = lazy(() =>
  import('./OnBehalf/OnBehalfMenu').then(module => ({
    default: module.OnBehalfMenu,
  }))
)

const OnBehalfListing = lazy(() =>
  import('./OnBehalf/OnBehalfListing').then(module => ({
    default: module.OnBehalfListing,
  }))
)

const TimeAttendanceMenu = lazy(() =>
  import('./OnBehalf/TimeAttendanceMenu').then(module => ({
    default: module.TimeAttendanceMenu,
  }))
)

const EmployeeDirectory = lazy(() =>
  import('./AGX_EmployeeDirectory/EmployeeDirectory').then(module => ({
    default: module.EmployeeDirectory,
  }))
)

const HomeRoutes = [
  //Project Expense Claim
  {
    props: { exact: true, path: '/home/myprojectexpenseclaim' },
    component: <HomeProjectExpenseClaim />,
  },
  {
    props: { exact: true, path: '/home/myprojectexpenseclaim/form/add' },
    component: <MyProjectExpenseClaimForm mode="add" />,
  },

  {
    props: {
      path:
        '/home/myprojectexpenseclaim/myprojectexpenseclaimform/:ProjectExpenseClaimHeaderID/edit',
    },
    component: <MyProjectExpenseClaimForm mode="edit" />,
  },
  {
    props: {
      path:
        '/home/myprojectexpenseclaim/myprojectexpenseclaimdetail/:ProjectExpenseClaimHeaderID',
    },
    component: <MyProjectExpenseClaimDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/myprojectexpenseclaim/ProjectExpenseClaimItemForm',
    },
    component: <ProjectExpenseClaimItemForm />,
  },

  {
    props: { exact: true, path: '/' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/MyMenu' },
    component: <MyMenu />,
  },
  {
    props: { exact: true, path: '/home/mypayrollinfo/mypayrollinfohome' },
    component: <MyPayrollHome />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrollinfo/viewpayrollhistory/payrollhistorymonthyear',
    },
    component: <PayrollHistoryMonthYear />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrollinfo/viewpayrollhistory/payrollhistorymonthview',
    },
    component: <PayrollHistoryMonthView />,
  },
  {
    props: { exact: true, path: '/datachangerequest/datachangerequestlisting' },
    component: <DataChangeRequestListing />,
  },
  {
    props: { exact: true, path: '/dataimportlisting' },
    component: <DataImportListing />,
  },
  {
    props: { exact: true, path: '/dataimportlisting/dataimport' },
    component: <DataImport />,
  },
  {
    props: { exact: true, path: '/dataimportlisting/useractivation' },
    component: <UserActivation />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestlisting/datachangerequestview',
    },
    component: <DataChangeRequestView />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestform/add',
    },
    component: <DataChangeRequestForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestform/edit',
    },
    component: <DataChangeRequestForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangepersonneldetail',
    },
    component: <DataChangePersonnelDetail />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestdependentaddform',
    },
    component: <DataChangeRequestDependentAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestdependenteditform/edit',
    },
    component: <DataChangeRequestDependentEditForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequesteducationaddform',
    },
    component: <DataChangeRequestEducationAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequesteducationeditform',
    },
    component: <DataChangeRequestEducationEditForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestcertificateaddform',
    },
    component: <DataChangeRequestCertificateAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestcertificateeditform',
    },
    component: <DataChangeRequestCertificateEditForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestemploymenthistoryaddform',
    },
    component: <DataChangeRequestEmploymentHistoryAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestemploymenthistoryeditform',
    },
    component: <DataChangeRequestEmploymentHistoryEditForm />,
  },

  {
    props: { exact: true, path: '/ProjectExpenseListing' },
    component: <ProjectExpenseListing />,
  },
  {
    props: { exact: true, path: '/ModuleCompanyListing' },
    component: <ModuleCompanyListing />,
  },

  {
    props: { exact: true, path: '/PayrollManagementListing' },
    component: <PayrollManagementListing />,
  },

  {
    props: { exact: true, path: '/DigitalReportingManagementListing' },
    component: <DigitalReportingManagementListing />,
  },
  {
    props: { exact: true, path: '/payroll/employee' },
    component: <PayrollManagementEmployeeListing />,
  },
  {
    props: { exact: true, path: '/payroll/monthlyremuneration' },
    component: <MonthlyRemunerationEmployeeListing />,
  },
  {
    props: { exact: true, path: '/payroll/employeesalarymenu' },
    component: <EmployeeSalaryMenu />,
  },
  {
    props: { exact: true, path: '/payroll/employeesalarylogslisting' },
    component: <EmployeeSalaryLogsListing />,
  },
  {
    props: { exact: true, path: '/payroll/employeeenquiry' },
    component: <EmployeeEnquiry />,
  },
  {
    props: { exact: true, path: '/payroll/employeeexcell' },
    component: <EmployeeExcell />,
  },
  {
    props: {
      exact: true,
      path: '/payroll/employeeexcell/employeevalidationexcell',
    },
    component: <EmployeeValidationExcell />,
  },
  {
    props: { exact: true, path: '/payroll/period' },
    component: <PayPeriodListing />,
  },

  // {
  //   props: { exact: true, path: '/staffconfirmation/staffconfirmationlisting' },
  //   component: <StaffConfirmationListing />,
  // },

  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/PayrollCompanySetting',
    },
    component: <PayrollCompanySetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/TemplateLibraryAssignment/PayrollTemplateLibraryAssignment',
    },
    // component: <PayrollTemplateLibraryAssignment />,
    component: <EnhancedPayrollTemplateLibraryAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/PayrollSetting/PayrollSetting',
    },
    component: <PayrollSetting />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/SystemAdmin/GeneralSetting/PayrollCompanySetting/GLAccountMappingAssignment',
  //   },
  //   component: <PayrollGLMappingAssignment />,
  // },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/Bank/BankListing',
    },
    component: <BankListing />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/GLAccountMappingAssignmentForm',
    },
    component: <PayrollGLAccountMappingAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/Bank/BankForm',
    },
    component: <BankForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixListing',
    },
    component: <UserDefineEPFPolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixForm',
    },
    component: <UserDefineEPFPolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixListing',
    },
    component: <UserDefineEmployerEPFVEPolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixForm',
    },
    component: <UserDefineEmployerEPFVEPolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/PayrollInitiation/InitiationPayCycle',
    },
    component: <PayrollInitiationPayCycle />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/Bank/InterBankListing',
    },
    component: <InterBankListing />,
  },

  {
    props: { exact: true, path: '/home/mysubordinate/subordinatelisting' },
    component: <SubordinateListing />,
  },
  {
    props: { exact: true, path: '/subordinatehome' },
    component: <SubordinateHome />,
  },
  {
    props: { exact: true, path: '/subordinatehome/timesheetlisting' },
    component: <TimeSheetSubordinateListing />,
  },
  {
    props: { exact: true, path: '/home/myleave' },
    component: <NewMyLeave />,
  },

  {
    props: { exact: true, path: '/home/myleave/detail' },
    component: <MyLeaveDetail />,
  },

  // {
  //   props: { exact: true, path: '/home/myleave/myleaveform' },
  //   component: <HomeLeaveForm />,
  // },

  {
    props: { exact: true, path: '/home/myleave/myleaveform' },
    component: <NewMyLeaveForm />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/menu' },
    component: <MyTimeSheetMenu />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/projecttimesheetlisting' },
    component: <ProjectTimeSheetListing />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/projecttimesheet' },
    component: <ProjectTimeSheetEntrySubmission />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/timesheetlisting' },
    component: <TimeSheetListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/mytimesheet/timesheetlisting/timesheetform',
    },
    component: <TimeSheetForm />,
  },

  {
    props: { path: '/home/myleave/myleaveform/edit' },
    component: <NewMyLeaveForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/home/myleave/entitlementtable' },
    component: <EntitlementTable />,
  },

  {
    props: {
      exact: true,
      path: '/home/myleave/entitlementtable/replacementleavetable',
    },
    component: <ReplacementLeaveTable />,
  },

  //Mileage Log
  {
    props: { exact: true, path: '/home/mymileagelog' },
    component: <HomeMileage />,
  },
  {
    props: { exact: true, path: '/home/mymileagelog/viewMileageLogGroup' },
    component: <ViewMileageLogGroup />,
  },
  {
    props: { exact: true, path: '/home/mymileagelog/MileageItemForm' },
    component: <MileageItemForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/home/mymileagelog/MileageLogForm/:MileageLogID/edit',
    },
    component: <MileageItemForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/home/mymileagelog/MyMileageLog/:MileageLogID',
    },
    component: <MyMileageLog />,
  },

  //Claim
  {
    props: { exact: true, path: '/home/myclaim' },
    component: <HomeClaim />,
  },
  {
    props: { exact: true, path: '/home/myclaim/form/add' },
    component: <MyClaimForm mode="add" />,
  },
  {
    props: { path: '/home/myclaim/myclaimform/:ClaimHeaderID/edit' },
    component: <MyClaimForm mode="edit" />,
  },
  {
    props: { path: '/home/myclaim/myclaimdetail/:ClaimHeaderID' },
    component: <MyClaimDetail />,
  },
  {
    props: { path: '/home/myclaim/myclaimitemdetail' },
    component: <MyClaimItemDetail />,
  },
  {
    props: { exact: true, path: '/home/myclaim/myclaimentitlementtable' },
    component: <MyClaimEntitlementTable />,
  },

  {
    props: { exact: true, path: '/home/myclaim/ClaimItemForm' },
    component: <ClaimItemForm />,
  },

  //Requisition

  {
    props: { exact: true, path: '/home/myrequisition' },
    component: <HomeRequisition />,
  },

  {
    props: { exact: true, path: '/home/myrequisition/view' },
    component: <MyRequisitionDetail />,
  },

  {
    props: { exact: true, path: '/home/myrequisition/myrequisitionform/add' },
    component: <HomeRequisitionForm mode="add" />,
  },
  {
    props: {
      path: '/home/myrequisition/myrequistionform/:RequisitionHeaderID/edit',
    },
    component: <HomeRequisitionForm mode="edit" />,
  },

  //DigitalDocument
  {
    props: { exact: true, path: '/home/digitaldocument' },
    component: <HomeDigitalDocument />,
  },
  {
    props: { exact: true, path: '/home/digitaldocument/Documents' },
    component: <HomeDigitalDocuments />,
  },

  {
    props: { exact: true, path: '/Employee/doc/general' },
    component: <GeneralDocument />,
  },
  {
    props: { exact: true, path: '/Employee/doc/personal' },
    component: <PersonalDocument />,
  },
  {
    props: { exact: true, path: '/Employee/doc/payslip' },
    component: <PayslipListing />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eaform' },
    component: <EAFormListing />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eletter' },
    component: <ELetterListing />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eletter/eletterlist' },
    component: <ELetterView />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eaform/eaformlist' },
    component: <EAFormView />,
  },
  {
    props: { exact: true, path: '/Employee/doc/payslip/paysliplist' },
    component: <PayslipView />,
  },

  //ToDoo
  // {
  //   props: { exact: true, path: '/home/todo' },
  //   component: <HomeTodo />,
  // },

  {
    props: { exact: true, path: '/home/todo' },
    component: <TodoMenu />,
  },
  {
    props: { exact: true, path: '/home/todo/claim' },
    component: <TodoClaim />,
  },
  {
    props: { exact: true, path: '/home/todo/projectexpense' },
    component: <TodoProjectExpense />,
  },
  {
    props: { exact: true, path: '/home/todo/leave' },
    component: <TodoLeave />,
  },
  {
    props: { exact: true, path: '/home/todo/requisition' },
    component: <TodoRequisition />,
  },
  {
    props: { exact: true, path: '/home/todo/overtime' },
    component: <TodoOverTime />,
  },
  {
    props: { exact: true, path: '/home/todo/overtime/details' },
    component: <TodoOverTimeDetails />,
  },
  {
    props: { exact: true, path: '/home/todo/batchovertimeentry' },
    component: <TodoBatchOverTime />,
  },
  {
    props: { exact: true, path: '/home/todo/batchovertimeentry/approval' },
    component: <TodoBatchOverTimeEntryApprovalForm />,
  },
  {
    props: { exact: true, path: '/home/todo/timeoff' },
    component: <TodoTimeOff />,
  },
  {
    props: { exact: true, path: '/home/todo/changeshift' },
    component: <TodoChangeShift />,
  },
  {
    props: { exact: true, path: '/home/todo/subkpilisting' },
    component: <TodoSubKpi />,
  },
  {
    props: { exact: true, path: '/home/todo/subkpilisting/approval' },
    component: <TodoSubKpiApproval />,
  },

  {
    props: { path: '/home/todo/approvalhistory' },
    component: <ApprovalHistory />,
  },
  {
    props: { exact: true, path: '/home/todo/tododetail' },
    component: <TodoDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/todoprojectexpensedetail' },
    component: <TodoProjectExpenseDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/claim/detail' },
    component: <TodoClaimDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/requisition/detail' },
    component: <TodoRequisitionDetailV2 />,
  },
  {
    props: { exact: true, path: '/home/todo/datachanges' },
    component: <ToDoDataChanges />,
  },
  {
    props: { exact: true, path: '/home/todo/datachanges/details' },
    component: <ToDoDataChangesDetails />,
  },
  {
    props: { exact: true, path: '/home/todo/helpdeskticket' },
    component: <TodoHelpdesk />,
  },
  {
    props: { exact: true, path: '/home/todo/training/request' },
    component: <TodoTraining type="Request" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/selfregistration' },
    component: <TodoTraining type="Self-Registration" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/nomination' },
    component: <TodoTraining type="Nomination" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/withdrawal' },
    component: <TodoTraining type="Withdrawal" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/details' },
    component: <TodoTrainingDetails />,
  },
  {
    props: { exact: true, path: '/home/todo/training/trainingevaluation' },
    component: <TodoTrainingSurvey />,
  },
  {
    props: { exact: true, path: '/home/todo/training/trainingevaluation/form' },
    component: <TodoTrainingSurveyDetails />,
  },
  {
    props: { exact: true, path: '/home/todo/attendancecorrectionrequest' },
    component: <TodoAttendanceCorrectionRequest />,
  },
  {
    props: {
      exact: true,
      path: '/home/todo/attendancecorrectionrequest/details',
    },
    component: <TodoAttendanceCorrectionRequestDetails />,
  },
  {
    props: { exact: true, path: '/home/todo/recruitmentrequisition' },
    component: <TodoRecruitmentRequisition />,
  },
  {
    props: { exact: true, path: '/home/todo/recruitmentrequisition/details' },
    component: <TodoRecruitmentRequisitionDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/recruitmentoffer' },
    component: <TodoRecruitmentOffer />,
  },
  {
    props: { exact: true, path: '/home/todo/recruitmentoffer/details' },
    component: <TodoRecruitmentOfferDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/recruitmenthiring' },
    component: <TodoRecruitmentHiring />,
  },
  {
    props: { exact: true, path: '/home/todo/recruitmenthiring/details' },
    component: <TodoRecruitmentHiringDetail />,
  },
  //Notification
  {
    props: { exact: true, path: '/home/notification' },
    component: <HomeNotification />,
  },
  {
    props: { exact: true, path: '/home/HomeNotificationDetail' },
    component: <HomeNotificationDetail />,
  },
  {
    props: { exact: true, path: '/home/HomeNotificationDataChangesDetail' },
    component: <HomeNotificationDataChangesDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/HomeNotificationRecruitmentRequisitionDetail',
    },
    component: <HomeNotificationRecruitmentRequisitionDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/HomeNotificationRecruitmentOfferDetail',
    },
    component: <HomeNotificationRecruitmentOfferDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/HomeNotificationRecruitmentHiringDetail',
    },
    component: <HomeNotificationRecruitmentHiringDetail />,
  },

  //Subordinates
  {
    props: { exact: true, path: '/home/mysubordinates/orgchart' },
    component: <MySubordinatesListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary',
    },
    component: <MySubordinatesSummary />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummaryconcise',
    },
    component: <MySubordinatesSummaryOlderVersion />,
  },
  {
    props: {
      exact: true,
      path: '/mysubordinates',
    },
    component: <MySubordinatesSummary />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/employeeinfo',
    },
    component: <EmployeeInfo />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/employeeinfo/details',
    },
    component: <EmployeeInfoDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/assetassigned',
    },
    component: <AssetAssignedDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords',
    },
    component: <LeaveRecordsDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleaverecorddetails',
    },
    component: <NewLeaveRecordDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleaverecorddetailsform',
    },
    component: <NewLeaveRecordDetailsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleaverecorddetailsform/edit',
    },
    component: <NewLeaveRecordDetailsForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/entitlementleavetable',
    },
    component: <EntitlementLeaveTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleavesubdetail',
    },
    component: <NewLeaveSubDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/replacementleavesubtable',
    },
    component: <ReplacementLeaveSubTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimrecords',
    },
    component: <ClaimRecordsDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/careerprogression',
    },
    component: <CareerProgressionDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionrecords',
    },
    component: <SubordinateRequisitionDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/subordinates',
    },
    component: <EmployeeSubordinates />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/employeejobinfodetails',
    },
    component: <EmployeeJobInfoDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/Subordinates/SubordinatePersonalProfile/PersonalStatInfo/PersonalStatDetails',
    },
    component: <SubordinatePersonalStatutoryDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Subordinates/SubordinatePersonalProfile/PersonalTP3/PersonalTP3Details',
    },
    component: <SubordinatePersonalTP3Details />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/personalinfo',
    },
    component: <SubordinateTreePersonalInfo />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance',
    },
    component: <SubordinateTreeTimeAttendance />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/menu',
    },
    component: <SubordinateTimeAttendanceMenu />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/mainmenu',
    },
    component: <SubordinateTimeAttendanceMainMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/batchentrylisting',
    },
    component: <MySubordinatesBatchOvertimeEntryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/batchentry/add',
    },
    component: <MySubordinatesBatchOvertimeEntryForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/batchentry/view',
    },
    component: <MySubordinatesBatchOvertimeEntryView />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/dutyroster',
    },
    component: <SubordinateDutyRoster />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/overtimelisting',
    },
    component: <SubordinateOvertimeListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/overtimeview',
    },
    component: <SubordinateOvertimeView />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/overtimeform',
    },
    component: <SubordinateOvertimeForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/overtimeform/edit',
    },
    component: <SubordinateOvertimeForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/timeofflisting',
    },
    component: <SubordinateTimeOffListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/timeoffform',
    },
    component: <SubordinateTimeOffForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/timeoffform/edit',
    },
    component: <SubordinateTimeOffForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/timeoffdetails',
    },
    component: <SubordinateTimeOffDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/attendancecorrectionrequestlist',
    },
    component: <SubordinateAttendanceCorrectionRequestListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/attendancecorrectionrequestlist/add',
    },
    component: <SubordinateAttendanceCorrectionRequestForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/attendancecorrectionrequestlist/details',
    },
    component: <SubordinateAttendanceCorrectionRequestDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftlisting',
    },
    component: <SubordinateChangeShiftRequestListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftdetails',
    },
    component: <SubordinateChangeShiftRequestDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftform',
    },
    component: <SubordinateChangeShiftRequestForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftform/edit',
    },
    component: <SubordinateChangeShiftRequestForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/attendance',
    },
    component: <SubordinateAttendanceAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendancerecords',
    },
    component: <SubordinateAttendanceRecords />,
  },

  //CareerProgression

  {
    props: {
      exact: true,
      path: '/home/mycareerprogression',
    },
    component: <CareerProgression />,
  },

  //Bulletin
  {
    props: {
      exact: true,
      path: '/home/bulletin',
    },
    component: <HomeBulletin />,
  },

  //Calendar

  {
    props: {
      exact: true,
      path: '/home/calendar',
    },
    component: <HomeCalendar />,
  },

  //Personnel Profile
  {
    props: { exact: true, path: '/home/personnelprofile' },
    component: <PersonalNav />,
  },
  {
    props: { exact: true, path: '/home/EmploymentInfo' },
    component: <EmploymentInfoNav />,
  },
  {
    props: {
      exact: true,
      path: '/home/EmploymentInfo/Diary',
    },
    component: <DiaryListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/EmploymentInfo/Diary/Form',
    },
    component: <DiaryForm />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personalinfo/details',
    },
    component: <PersonalInfoDetails />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personalinfo/details/edit',
    },
    component: <PersonalInfoDetailsForm />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaljobinfo/details',
    },
    component: <PersonalJobDetails />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personalstatinfo/details',
    },
    component: <PersonalStatDetails />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaldependent/details',
    },
    component: <PersonalDependentDetails />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaldependent/personaldependentview',
    },
    component: <PersonalDependentView />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaldependent/add',
    },
    component: <DependentForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Education/List',
    },
    component: <EducationList />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Education/Form',
    },
    component: <EducationForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Certificate/List',
    },
    component: <CertificateListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Certificate/Form',
    },
    component: <CertificateForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/References/List',
    },
    component: <ReferencesListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/References/Form',
    },
    component: <ReferencesForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/HealthInfo/Form',
    },
    component: <HealthInfoForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/JobHistory/List',
    },
    component: <JobHistoryListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/JobHistory/Form',
    },
    component: <JobHistoryForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/UserDefine/Form',
    },
    component: <UserDefineForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/PersonalDocument/List',
    },
    component: <PersonalDocumentListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaltp3/details',
    },
    component: <PersonalTP3Details />,
  },

  //Home Asset Assigned
  {
    props: {
      exact: true,
      path: '/home/homeassetassigned/homeasset',
    },
    component: <AssetAssignedHome />,
  },

  //Home Insurance Scheme
  {
    props: {
      exact: true,
      path: '/home/homeinsurancescheme/insurancescheme',
    },
    component: <InsuranceSchemeHome />,
  },

  // Time Attendance

  {
    props: {
      exact: true,
      path: '/home/timeattendance',
    },
    component: <TimeAttendanceView />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtimelist',
    },
    component: <OvertimeListing />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtimedetails',
    },
    component: <OvertimeDetails />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtime-application',
    },
    component: <OvertimeForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtime-application/edit',
    },
    component: <OvertimeForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/attendance',
    },
    component: <EmpAttendance />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/timeofflist',
    },
    component: <TimeOffListing />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/timeoffdetails',
    },
    component: <TimeOffDetails />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/timeoff-application',
    },
    component: <TimeOffForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/timeoff-application/edit',
    },
    component: <TimeOffForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/checkIn',
    },
    component: <EmpCheckIn />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/checkOut',
    },
    component: <EmpCheckOut />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/checkIn/history',
    },
    component: <CheckInHistory />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/attendancecorrectionrequestlist',
    },
    component: <MyMenuAttendanceCorrectionRequestListing />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/attendancecorrectionrequestlist/add',
    },
    component: <MyMenuAttendanceCorrectionRequestForm />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/attendancecorrectionrequestlist/details',
    },
    component: <MyMenuAttendanceCorrectionRequestView />,
  },

  //// Delegation ////

  {
    props: {
      exact: true,
      path: '/Delegation/DelegationListing',
    },
    component: <DelegationListing />,
  },

  {
    props: {
      exact: true,
      path: '/Delegation/DelegationForm',
    },
    component: <DelegationForm />,
  },

  {
    props: {
      exact: true,
      path: '/Delegation/DelegationView',
    },
    component: <DelegationView />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestList',
    },
    component: <ChangeShiftRequestListing />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestForm',
    },
    component: <ChangeShiftRequestForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestForm/edit',
    },
    component: <ChangeShiftRequestForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestdetails',
    },
    component: <ChangeShiftRequestDetails />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/checkIn/history',
    },
    component: <CheckInHistory />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/mydutyRoster',
    },
    component: <MyDutyRoster />,
  },

  {
    props: {
      exact: true,
      path: '/home/todo/calendar',
    },
    component: <TodoCalendar />,
  },

  {
    props: {
      exact: true,
      path: '/home/todo/LeaveView',
    },
    component: <TodoLeaveView />,
  },

  {
    props: {
      exact: true,
      path: '/home/todo/OvertimeView',
    },
    component: <TodoOvertimeDetails />,
  },
  {
    props: {
      exact: true,
      path: '/home/mykpi/staffversion',
    },
    component: <StaffVersion />,
  },
  {
    props: {
      exact: true,
      path: '/home/mykpi/staffversion/staffkpiform',
    },
    component: <StaffKpiForm />,
  },
  {
    props: {
      exact: true,
      path: '/home/mykpi/staffversion/staffkpiform/staffkpidetailform',
    },
    component: <StaffKpiDetailForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mykpi/staffversion/staffkpiform/staffkpidetailform/scaleform',
    },
    component: <ScaleForm />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/employmentinfo',
    },
    component: <SubordinateTreeEmployeeInfo />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timesheet',
    },
    component: <SubordinateTreeTimesheet />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/leave',
    },
    component: <SubordinateTreeLeave />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/claim',
    },
    component: <SubordinateTreeClaim />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/projectexpense',
    },
    component: <SubordinateTreeProjectExpense />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/employmentinfonav',
    },
    component: <SubordinateEmployeeInfoNav />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav',
    },
    component: <SubordinatePersonnelInfo />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/personalinfoform',
    },
    component: <SubordinatePersonnelInfoForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/educationlist',
    },
    component: <SubordinatePersonnelEducation />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/certificatelist',
    },
    component: <SubordinatePersonnelCert />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/references',
    },
    component: <SubordinatePersonnelRef />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/healthinfo',
    },
    component: <SubordinatePersonnelHealth />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/dependentinfo',
    },
    component: <SubordinatePersonnelDependent />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/jobhistory',
    },
    component: <SubordinatePersonnelJobHistory />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/otherinfo',
    },
    component: <SubordinatePersonnelAdditionalInfo />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/docinfo',
    },
    component: <SubordinatePersonnelDocInfo />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leave',
    },
    component: <SubordinateLeave />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leavedetail',
    },
    component: <SubordinateLeaveDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaveform',
    },
    component: <SubordinateLeaveForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/Entitlement',
    },
    component: <SubordinateEntitlementTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/ReplacementLeave',
    },
    component: <SubordinateReplacementLeaveTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claim',
    },
    component: <SubordinateClaim />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimdetail',
  //   },
  //   component: <SubordinateClaimDetail />,
  // },
  {
    props: {
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimdetail/:ClaimHeaderID',
    },
    component: <SubordinateClaimDetail />,
  },
  {
    props: {
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimitemdetail',
    },
    component: <SubordinateClaimItemDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimform',
    },
    component: <SubordinateClaimForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimform/claimItem',
    },
    component: <SubordinateClaimItemForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/ClaimEntitlement',
    },
    component: <SubordinateClaimEntitlementTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpense',
    },
    component: <SubordinateProjectExpense />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpenseform',
    },
    component: <SubordinateProjectExpenseForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpenseform/claimItem',
    },
    component: <SubordinateProjectExpenseClaimItemForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpensedetail',
    },
    component: <SubordinateProjectExpenseDetail />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionListing',
    },
    component: <SubordinateRequisitionListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionForm',
    },
    component: <SubordinateRequisitionForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionrecords',
    },
    component: <SubordinateRequisitionDetail />,
  },

  {
    props: {
      exact: true,
      path: '/home/mypayrolldocuments/mypayrolldocumentshome',
    },
    component: <MyPayrollDocuments />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrolldocuments/mypayrolldocumentshome/payslipdocuments',
    },
    component: <PayslipDocumentsListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrolldocuments/mypayrolldocumentshome/eaformdocuments',
    },
    component: <EAFormDocumentsListing />,
  },
  {
    props: {
      exact: true,
      path: '/HelpdeskListing',
    },
    component: <HelpdeskListing />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectTimesheet/Approver/:token',
    },
    component: <ExternalSummaryTimesheet />,
  },
  {
    props: {
      exact: true,
      path: '/home/EmploymentInfo/EmployeeUserDefineFieldsView',
    },
    component: <EmployeeUserDefineFieldsView />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf',
    },
    component: <OnBehalf />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/OnBehalfListing',
    },
    component: <OnBehalfListing />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveListing',
    },
    component: <SubordinateLeave />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveListing/Entitlement',
    },
    component: <SubordinateEntitlementTable />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveDetail',
    },
    component: <SubordinateLeaveDetail />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveForm',
    },
    component: <SubordinateLeaveForm />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/TimeAttendanceMenu',
    },
    component: <TimeAttendanceMenu />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Claim/ClaimListing',
    },
    component: <SubordinateClaim />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/TimeAttendance/OvertimeListing',
    },
    component: <SubordinateOvertimeListing />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/TimeAttendance/TimeOffListing',
    },
    component: <SubordinateTimeOffListing />,
  },
  {
    props: {
      exact: true,
      path: '/EmployeeDirectory',
    },
    component: <EmployeeDirectory />,
  },
]

export default HomeRoutes
